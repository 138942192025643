//Copyright Breast Cancer Index || 2021
//Main Page

import React, { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';

import ExploreModals from './ExploreModals';

import modals from '../../util/explore-report-providers.json';

import {
    Navbar,
    SubFooter,
    Footer,
    BackToTop,
    FloatingPetal,
    SEO
} from "../../lib";

import ReactGA from 'react-ga';

function ExploreTheReportProviders() {
  const isMob = window.innerWidth < 767;
  ReactGA.pageview(window.location.pathname + window.location.search);

  let modalNum = 0;

  let activeModal = "";

  const [front, setFront] = useState(true);

  const [modal, setModal] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");

  const [show, setShow] = useState(false);

  const [vertModals, hideVertModals] = useState(true);

  // Sets the modal to the correct information from the JSON
  const changeModal = (ms, e) => {
    if(ms != modalNum){
      modalNum = ms;
      activeModal = modals.find(e => e.id === modalNum);

      if(ms === "2yes" || ms === '2no') {setModal('2')} else {setModal(ms)};
	  setModalTitle(activeModal.title);
      setModalBody(activeModal.body);
      handleShow();
      e.target.classList.add('active');
    }
  }
  
  const handleClose = () => {
    setShow(false);
    document.querySelector(".btn-popmodal.active").classList.remove("active");
  };
  const handleShow = () => setShow(true);
  return (
    <div>
      <SEO title='Explore the Breast Cancer Index Report' description='Breast Cancer Index provides a clear, easy-to-read report so that you and your patients can discuss their endocrine treatment plan beyond year 5.' hideSuffix={true}/>
      <Navbar type="providers" />
      
      {/* <!-- INTERACTIVE HEADER --> */}
      <header className="page-header interactive">
        <div className="container-fluid w-100 bgc hcp-gradient not-round interactive">
          <div className="container interactive-content">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 pt-5 pr-md-0">
                  <h2 className="mb-4">
				  	Not Another <br />Minute
                  </h2>
                  <p>
                    <b>
						Standardize your recommendations
						for endocrine therapy duration
						using the only guideline-recognized
						biomarker for prediction of benefit
						beyond 5 years.<sup>1-3</sup>
					</b>
                  </p>
                  <p>
                    Treating breast cancer is complicated enough — Breast Cancer Index<sup>&reg;</sup> results are provided in one clear and easy-to-understand report so you and your patients can have an informed and productive conversation about their endocrine treatment plan beyond year 5.
                  </p>
                  <p className="highlight">
				  	Click the blue number tabs to explore the report. <br />
					Use the arrows below the report to switch between a YES result and a NO result.
                  </p>
              </div>
              <div className="col-12 col-md-8 col-lg-9 py-5">
                <div className="report-container px-4 px-sm-0">
                  <div className={"report front" + (front ? "" : " d-none")} >
                    <div className="verticle-hide">
                      <button onClick={(e) => {changeModal("1", e)}} className="btn btn-popmodal b1">1</button>
                      <button onClick={(e) => {changeModal("2yes", e)}} className="btn btn-popmodal b2">2</button>
                      <button onClick={(e) => {changeModal("3a", e)}} className="btn btn-popmodal b3a">3<span>a</span></button>
					  <button onClick={(e) => {changeModal("3b", e)}} className="btn btn-popmodal b3b">3<span>b</span></button>
                    </div>

                    <img src="/report-yes.jpg" alt="Report" className="w-100" />
                    
                    <button className="flip-report-btn" onClick={() => {setFront(false)}}>
                      <div className="controls mt-4">
						<p className="instruction mb-0">
							Click to View a
						</p>
						<p className="side">
							NO Result
						</p>
                      </div>
                    </button>

                  </div>
                  <div className={"report back" + (!front ? "" : " d-none")} >
                    <div className="verticle-hide">
					  <button onClick={(e) => {changeModal("1", e)}} className="btn btn-popmodal b1">1</button>
                      <button onClick={(e) => {changeModal("2no", e)}} className="btn btn-popmodal b2">2</button>
                      <button onClick={(e) => {changeModal("3", e)}} className="btn btn-popmodal b3">3</button>
                    </div>
                    
                    <img src="/report-no.jpg" alt="Report" className="w-100" />

                    <button className="flip-report-btn" onClick={() => {setFront(true)}}>
                      <div className="controls mt-4">
					  	<p className="instruction mb-0">
                          Click to View a
                        </p>
                        <p className="side">
						  YES Result
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row d-block d-sm-none mobile-definitions">
              <div className="col-12">
                {modals.map(m => (
                  <div className="row" id={m.id}>
                    <div className="col-1">
                      <div className="btn btn-popmodal count-mobile">{m.id}</div>
                    </div>
                    <div className="col-11">
                      <p className="p-3 p-md-5" dangerouslySetInnerHTML={{ __html: m.body }}/>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="bg-pattern"></div>
        </div>
      </header>

      <FloatingPetal type="provider" />

      {/* <!-- HCP CONTENT AREA --> */}
      <div className="container hcp-content pb-xl-5">
        <div className="row">
          <div className="col-12 pt-5">
            <h2 className="mb-4 px-5 text-center">
            Regardless of their Breast Cancer Index results, 100% of women deserve to know what is right for them. 
            </h2>
            <p className="text-center m-5">
              <a href="/order-test" className="btn btn-primary hcp">Order Breast Cancer Index<sup>&reg;</sup></a>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="row pb-xl-5">
          <div className="col">
            <p className="text-center ref m-5">
				<b>References:</b>{' '}
				<b>1.&nbsp;</b>Sgroi DC, et al. J Natl Cancer Inst. 2013;105(14):1036-1042.{' '}
				<b>2.&nbsp;</b>Bartlett JMS, et al. Clin Cancer Res. DOI: 10.1158/1078-0432.CCR-21-3385. 2022.{' '}
				<b>3.&nbsp;</b>Noordhoek I, et al. Clin Cancer Res. 2021;27(1):311-319.
            </p>
          </div>
        </div>
      </div>

      {/* <!-- Modal Header Popup --> */}
      <Modal className="fade" show={show} onHide={handleClose}>
        <Modal.Header>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
			  	<div className="d-flex align-items-center mb-3">
					<div className="btn btn-popmodal count mb-0 mr-2">{modal}</div>
					<div className="title"><b>{modalTitle}</b></div>
				</div>
                <p dangerouslySetInnerHTML={{ __html: modalBody }}/>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      

      <BackToTop />

      <SubFooter
          site="hcp"
          image="/img-hero4.png"
          icons={[
          {
              href: "/order-test",
              img: "/icon-clipboard.svg",
              alt: " Order Breast Cancer Index",
              text1: "Order Breast",
              text2: "Cancer Index",
              className: "clipboard"
          }
          ,{
              href: "/resources-providers",
              img: "/icon-gear.svg",
              alt: " Resources for Providers",
              text1: "Resources",
              text2: "for Providers"
            }
          ]} 
          type='hasHero'/>
          
      <Footer
        showTopLinks={true} topLinks={[
          {link: "/", label: "For Patients"},
          {link: "/know-someone-with-breast-cancer", label: "For Supporters"}
        ]}
        showSecondaryLinks={true} secondaryLinks={[
          {link: "/order-test", label: "Order The Test"},
          {link: "/contact-providers", label: "Contact"}
        ]} />

    </div>
  );
}

export default ExploreTheReportProviders;
